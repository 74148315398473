var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c("div", { staticClass: "vx-row no-gutter" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex justify-items-center mb-2 pt-4" },
                        [
                          _c("img", {
                            staticClass: "mx-auto",
                            staticStyle: { "max-width": "120px" },
                            attrs: {
                              src: require("@/assets/images/logo/logo.png"),
                              alt: "logo",
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "p-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-card__title mb-8 grid justify-items-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "w-full flex justify-center mt-10",
                              },
                              [
                                _c("img", {
                                  staticClass: "mx-auto mb-4",
                                  attrs: {
                                    src: require("@/assets/images/pages/not-authorized.png"),
                                    alt: "graphic-not-authorized",
                                  },
                                }),
                              ]
                            ),
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("voce-nao-esta-autorizado"))
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "voce-nao-esta-autorizado-a-acessar-este-"
                                    )
                                  )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto",
                                attrs: { size: "large" },
                                on: { click: _vm.back },
                              },
                              [_vm._v(_vm._s(_vm.$t("common.back")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }