<!-- =========================================================================================
    File Name: NotAuthorized.vue
    Description: Not Authorized Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row no-gutter">
              <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                <div class="flex justify-items-center mb-2 pt-4">
                  <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-width: 120px;"/>
                </div>
                <div class="p-4">
                  <div class="vx-card__title mb-8 grid justify-items-center">
                    <div class="w-full flex justify-center mt-10">
                      <img src="@/assets/images/pages/not-authorized.png" alt="graphic-not-authorized" class="mx-auto mb-4"/>
                    </div>
                    <h4 class="mb-4">{{ $t('voce-nao-esta-autorizado') }}</h4>
                    <p> {{ $t('voce-nao-esta-autorizado-a-acessar-este-') }}</p>
                  </div>
                  <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8">
                    <vs-button size="large" @click="back" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">{{ $t('common.back') }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

<!--
  <div class="h-screen flex w-full bg-img">
    <div class="vx-row">
      <div class="w-full flex justify-center mt-10">
        <img src="@/assets/images/logo/logo.png" alt="login" class="mx-auto" style="max-width: 150px;"/>
      </div>
    </div>
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <img
        src="@/assets/images/pages/not-authorized.png"
        alt="graphic-not-authorized"
        class="mx-auto mb-4"/>
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">Você não está autorizado!</h1>
      <p class="sm:mx-0 mx-4 sm:mb-12 mb-8 d-theme-text-inverse">
          Você não está autorizado a acessar este conteúdo, entre em contato com o administrador do sistema.
      </p>
      <vs-button size="large" @click="back">Voltar</vs-button>
    </div>
  </div>
  -->
</template>


<script>
export default {
  data() {
    return {}
  },
  methods: {
    back() {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        this.$router.push('/')
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>
